<template>
    <div>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('refresh')">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <template v-slot:extension v-if="!hideParameters">
                <span class="mr-3">Parameters: </span>
                <template v-for="param in localVisibleParameters">
                    <parameter v-model="param.value" :key="param.key"></parameter>
                </template>
            </template>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card flat tile :loading="loading">
            <v-card-text>
                <slot name="content">Report content</slot>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Parameter from '@/components/Parameter.vue';

export default {
    props: {
        parameters: {       // array of objects. Object definition { type: 'date|store|select', key: '', label: '', value: '', visible: true|false, icon: '', options: [] }
            type: Array,
            default: () => {
                return [];
            }
        },
        hideParameters: {   // whether to hide the parameters from the user
            type: Boolean,
            default: false,
        },
        parameterPosition: {         // position of the parameters. Possible values top, left, right, buttom
            type: String,
            default: 'top'
        },
        title: {
            type: String,
            default: 'Report Title'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            localVisibleParameters: []
        }
    },
    computed: {
        visibleParameters() {
            let param = this.parameters.filter(p => {
                return p.visible === true;
            });

            if( param == undefined || param == null )
                return [];
            
            return param;
        }
    },
    watch: {
        
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.visibleParameters.forEach(p => {
                this.localVisibleParameters.push({
                    key:    p.key,
                    value:  p
                });
            });

            // this.localVisibleParameters = [{
            //     key: 'p',
            //     value: {
            //         type: 'date', 
            //         key: 'p', 
            //         label: 'Date',
            //         value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            //         visible: true,
            //         icon: 'mdi-calendar-range'
            //     }
            // },{
            //     key: 's',
            //     value: {
            //         type: 'store', 
            //         key: 's', 
            //         label: 'Store',
            //         value: '',
            //         visible: true,
            //         icon: 'mdi-store'
            //     }
            // }];
        },
    },
    components: {
        Parameter
    }
}
</script>

<style scoped>

</style>
