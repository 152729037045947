<template>
    <div class="mx-1">
        <datepicker v-model="value" v-if="value.type == 'date'"></datepicker>

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="value.value" transition="scale-transition" offset-y min-width="290px" v-if="value.type == 'store'">
            <template v-slot:activator="{ on }">
                <v-chip label v-on="on">
                    <v-avatar left v-if="value.icon != undefined && value.icon.length > 0">
                        <v-icon>{{ value.icon }}</v-icon>
                    </v-avatar>
                    <span class="caption mr-1" v-else>{{ value.label }}: </span>
                    {{ value.value.length == 0 ? ' Not set' : value.value }}
                </v-chip>
            </template>
            <v-list>
                <v-list-item v-for="(store, i) in stores" :key="i" @click="$refs.menu.save(store)">
                    <v-list-item-content>
                        {{ store }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="value.value" transition="scale-transition" offset-y min-width="290px" v-if="value.type == 'select'">
            <template v-slot:activator="{ on }">
                <v-chip label v-on="on">
                    <v-avatar left v-if="value.icon != undefined && value.icon.length > 0">
                        <v-icon>{{ value.icon }}</v-icon>
                    </v-avatar>
                    <span class="caption mr-1" v-else>{{ value.label }}: </span>
                    {{ value.value.length == 0 ? ' Not set' : value.value }}
                </v-chip>
            </template>
            <v-list>
                <v-list-item v-for="(opt, i) in value.options" :key="i" @click="$refs.menu.save(opt)">
                    <v-list-item-content>
                        {{ opt }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
import Datepicker from '@/components/Datepicker.vue';

export default {
    props: {
        value: {
            type: Object,
            default: function() {
                return { 
                    type: 'date', 
                    key: 'p', 
                    label: 'Use date',
                    value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
                    visible: true,
                    icon: ''
                }
            }
        }
    },
    data: () => {
        return {
            menu: false,
            stores: [
                'San Juan', 'Ponce', 'Bayamon', 'Arecibo', '65 Infanteria', 'Mayaguez', 'Catano', 'Winter Park'
            ]
        }
    },
    computed: {
        dateRangeText () {
            return this.value.value.join(' to ');
        },
    },
    watch: {

    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
             
        },
        dateSelect( selection ) {
            switch( selection ) {
                case 'thismonth':
                    this.value.value = [
                        moment().startOf('month').format('YYYY-MM-DD'),
                        moment().endOf('month').format('YYYY-MM-DD')
                    ];
                    break;
                case 'lastmonth':
                    var prevMonth = new moment().subtract(1, 'months');
                    this.value.value = [
                        prevMonth.startOf('month').format('YYYY-MM-DD'),
                        prevMonth.endOf('month').format('YYYY-MM-DD')
                    ];
                    break;
                case 'yeartodate':
                    this.value.value = [
                        moment().startOf('year').format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                    break;
                case 'prevyear':
                    var prevYear = new moment().subtract(1, 'year');
                    this.value.value = [
                        prevYear.startOf('year').format('YYYY-MM-DD'),
                        prevYear.endOf('year').format('YYYY-MM-DD')
                    ];
                    break;
                default:
                    break;
            }
        }
    },
    components: {
        Datepicker
    }
}
</script>

<style scoped>
.v-picker.v-card {
    box-shadow: none;
}
</style>