<template>
    <div>
        <report title="Delinquent Residents Report" :parameters="parameters" @refresh="getReportData()" :loading="loading" hide-parameters>
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="headers" :items="rptData" :search="filter" :items-per-page="200">
                    <template v-slot:item.balance="{ item }">
                        {{ formatMoney( item.balance ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import Report from '@/components/Report.vue';

export default {
    data: () => ({
        parameters: [],
        headers: [
            { text: 'Unit', value: 'unitNumber', sortable: true },
            { text: 'Unit Type', value: 'buildingType', sortable: true },
            { text: 'Late Payments', value: 'latePayments', align: 'right', sortable: true, summarizeFn: 'sum', formatter: '' },
            { text: 'Balance', value: 'balance', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' }
        ],
        rptData: [],
        filter: '',
        loading: false,
    }),
    computed: {
        view() {
            let view = '';
            view = this.parameters.find( p => {
                return p.key == 'selectView';
            });

            if( view == null || view == undefined )
                return '';

            return view.value.toLowerCase();
        }
    },
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get('/admin/units/delinquent');
                this.loading = false;

                this.rptData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                console.log(error);
            }
        },
        summarize( col ) {
            let result = 0;

            if( this.rptData.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum') {
                result = this.rptData.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
            }
        }
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    height: auto;
    border-top: 3px double #000;
}
</style>