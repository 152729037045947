<template>
    <div>
        <v-card flat>
            <v-toolbar dark extended flat>
                <v-toolbar-title>Reports</v-toolbar-title>
                
                <v-spacer></v-spacer>

                <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item  v-for="(item, index) in reports" :key="index" @click="report = item.id">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>

            <rpt-delinquent-residents class="mx-2" style="margin-top: -48px;" v-if="report == 0"></rpt-delinquent-residents>
            <rpt-payments class="mx-2" style="margin-top: -48px;" v-else-if="report == 1"></rpt-payments>
        </v-card>
    </div>
</template>

<script>
import RptDelinquentResidents from '@/components/RptDelinquentResidents.vue';
import RptPayments from '@/components/RptPayments.vue';

export default {
    data: () => {
        return {
            reports: [
                { title: 'Delinquent Residents Report', id: 0 },
                { title: 'Payments', id: 1 }
            ],
            report: null,
        }
    },
    components: {
        RptDelinquentResidents,
        RptPayments
    }
}
</script>

<style scoped>

</style>