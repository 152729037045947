<template>
    <div>
        <report title="Payments Report" :parameters="parameters" @refresh="getReportData()" :loading="loading">
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="headers" :items="rptData" :search="filter" :items-per-page="200">
                    <template v-slot:item.paidAmount="{ item }">
                        {{ formatMoney( item.paidAmount ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import Report from '@/components/Report.vue';

export default {
    data: () => ({
        parameters: [{ 
            type: 'date', 
            key: 'pdate', 
            label: 'Payment date', 
            value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)], 
            visible: true, 
            icon: '' 
        },{
            type: 'select', 
            key: 'selectView', 
            label: 'Units', 
            value: 'All', 
            visible: true, 
            icon: '',
            options: ['All', 'Houses', 'Apartments']
        }],
        headers: [
            { text: 'Unit', value: 'unitNumber', sortable: true },
            { text: 'Unit Type', value: 'buildingType', sortable: true },
            { text: 'Payment Date', value: 'paymentDateFormatted', sortable: true },
            { text: 'Payment Method', value: 'paymentMethod', sortable: true },
            { text: 'Payment Confirmation', value: 'paymentConfirmationNumber', sortable: true },
            { text: 'Payment Amount', value: 'paidAmount', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' }
        ],
        rptData: [],
        filter: '',
        loading: false,
    }),
    computed: {
        view() {
            let view = '';
            view = this.parameters.find( p => {
                return p.key == 'selectView';
            });

            if( view == null || view == undefined )
                return '';

            return view.value.toLowerCase();
        },
        unitParaValue: function() {
            switch( this.parameters[1].value ) {
                case 'Houses':      return '1';
                case 'Apartments':  return '2';
                default:            return '%';
            }
        }
    },
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/payments/${this.parameters[0].value[0]}/${this.parameters[0].value[1]}/${encodeURI( this.unitParaValue )}`);
                this.loading = false;

                this.rptData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                console.log(error);
            }
        },
        summarize( col ) {
            let result = 0;

            if( this.rptData.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum') {
                result = this.rptData.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
            }
        }
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    height: auto;
    border-top: 3px double #000;
}
</style>