<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="param.value" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-chip label v-on="on">
                <v-avatar left v-if="value.icon != undefined && value.icon.length > 0">
                    <v-icon>{{ value.icon }}</v-icon>
                </v-avatar>
                <span class="caption mr-1" v-else>{{ value.label }}: </span>
                {{ dateRangeText }}
            </v-chip>
        </template>

        <v-card flat color="grey lighten-4">
            <v-row no-gutters>
                <v-col cols="4">
                    <v-list dense color="grey lighten-4">
                        <v-subheader>Periods</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item @click="dateSelect('thisweek')">
                                <v-list-item-content>
                                    <v-list-item-title>This week</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dateSelect('lastweek')">
                                <v-list-item-content>
                                    <v-list-item-title>Last week</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dateSelect('thismonth')">
                                <v-list-item-content>
                                    <v-list-item-title>This month</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dateSelect('lastmonth')">
                                <v-list-item-content>
                                    <v-list-item-title>Last month</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dateSelect('yeartodate')">
                                <v-list-item-content>
                                    <v-list-item-title>Year to date</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dateSelect('prevyear')">
                                <v-list-item-content>
                                    <v-list-item-title>Last year</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="8">
                    <v-date-picker v-model="param.value" range no-title scrollable class="mr-10" ref="datePicker">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="updateDates()">OK</v-btn>
                    </v-date-picker>
                </v-col>
            </v-row>
        </v-card>
    </v-menu>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        value: {
            type: Object,
            default: () => {
                return {}; 
            }
        }
    },
    data: () => {
        return {
            menu: false,
            param: {}
        }
    },
    computed: {
        dateRangeText () {
            if( this.param.value == undefined )
                return '';

            return this.param.value.join(' to ');
        },
    },
    watch: {
        value: function(v) {
            this.param = v;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            this.param = this.value;
            // console.log(this.param);
        },
        dateSelect: function(selection) {
            switch( selection ) {
                case 'thisweek':
                    this.param.value = [
                        moment().startOf('week').format('YYYY-MM-DD'),
                        moment().endOf('week').format('YYYY-MM-DD')
                    ];
                    break;
                case 'lastweek':
                    var prevWeek = new moment().subtract(1, 'weeks');
                    this.param.value = [
                        prevWeek.startOf('week').format('YYYY-MM-DD'),
                        prevWeek.endOf('week').format('YYYY-MM-DD')
                    ];
                    break;
                case 'thismonth':
                    this.param.value = [
                        moment().startOf('month').format('YYYY-MM-DD'),
                        moment().endOf('month').format('YYYY-MM-DD')
                    ];
                    break;
                case 'lastmonth':
                    var prevMonth = new moment().subtract(1, 'months');
                    this.param.value = [
                        prevMonth.startOf('month').format('YYYY-MM-DD'),
                        prevMonth.endOf('month').format('YYYY-MM-DD')
                    ];
                    break;
                case 'yeartodate':
                    this.param.value = [
                        moment().startOf('year').format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                    break;
                case 'prevyear':
                    var prevYear = new moment().subtract(1, 'year');
                    this.param.value = [
                        prevYear.startOf('year').format('YYYY-MM-DD'),
                        prevYear.endOf('year').format('YYYY-MM-DD')
                    ];
                    break;
                default:
                    break;
            }
        },
        updateDates: function() {
            this.$refs.menu.save( this.param.value );
            this.$emit( 'input', this.param );
        },
    }
}
</script>

<style scoped>

</style>